@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Syncopate:wght@400;700&display=swap");


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 120s linear;
  }
}*/

.App-header {
  background-color: #FFF7EC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: black;
}

/*.App-link {
  color: var(--text-color);
}*/

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --black-color: hsl(0, 0%, 5%);
  --black-color-light: hsl(0, 0%, 70%);
  --white-color: hsl(0, 0%, 100%);
  --title-color: hsl(0, 0%, 5%);
  --text-color: hsl(0, 0%, 55%);
  --body-color: #FAF2E9;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --second-font: "Syncopate", sans-serif;
  --biggest-font-size: 2.5rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-bold: 700;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media (max-width: 768px) {
  .options-list {
    column-count: 1 !important;
  }
}

@media screen and (min-width: 1152px) {
  :root {
    --biggest-font-size: 4.5rem;
    --h2-font-size: 1.5rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }

  .App-logo{
    height: 40vmin;
  }
}

body {  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}
.courts-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: var(--normal-font-size);
  gap: 20px;
}

.ContactInfo {
  font-size: var(--normal-font-size);
  display: flex;
  gap: 20px;

}

.form-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: var(--normal-font-size);
  gap: 20px;
}

.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1023px) {
  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: var(--body-color);
    box-shadow: -1px 0 16px hsla(0, 0%, 0%, .1);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem;
    transition: right .4s;
  }
  .App-logo{
    height: 60vmin;
  }
  }


  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  /* For large devices */
@media screen and (min-width: 1023px) {
  .nav {
    height: calc(--header-height) + 2rem;
  }

  .nav__toggle, .nav__close{
    display: none;
  }

  .nav__menu{
    width: initial;
  }

  .nav__list{
    flex-direction: row;
    column-gap: 4rem;
  }

  .home__container{
    height: 100vh;
    grid-template-columns: repeat(2, 400px);
    place-items: center;
    column-gap: 2rem;
  }

  .home__data{
    text-align: initial;
  }

  .home__social {
    align-content: center;
    top: 0;
    bottom: 0;
  }
  .App-logo{
    height: 40vmin;
  }
} 


a {
  text-decoration: none;
  color: black;
}


@media screen and (min-width: 1152px) {
  .container {
    margin-inline: auto;
  }

  .home__container{
    grid-template-columns: 430px 600px;
    column-gap: 6rem;
  }

  .home__title{
    margin-bottom: 1.25rem;
  }

  .home__description{
    font-size: var(--normal-font-size);
    margin-bottom: 3rem;
  }

  .home__space {
    width: 600px;
  }

  .home__circle {
    width: 500px;
    height: 500px;
  }
  .App-logo{
    height: 50vmin;
  }
}

@media screen and (min-width: 1248px) {
  .home__social {
    right: -3rem;
    top: 3rem;
    row-gap: 2rem;
  }

  .home__social-link {
    font-size: 1.5rem;
  }
  .App-logo{
    height: 50vmin;
  }
}

@media screen and (max-width: 320px) {
  .container {
    margin-inline: 1rem;
  }

  .home__title{
    font-size: 2rem;
  }
  .home__circle{
    width: 220px;
    height: 220px;
  }
  .App-logo {
    height: 60vmin;
  }
}

@media screen and (min-width: 576px) {
  .nav__menu{
    width: 60%;
  }

  .home__container {
    grid-template-columns: 350px;
    justify-content: center;
  }

  .home__data{
    text-align: center;
  }
  .App-logo{
    height: 40vmin;
  }
}

.additional-info {
  background-color: #274C58;
  border-radius: 15px;
  padding: 5px;
  width: 300px;
  margin: 20px auto;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: var(--white-color);
  font-size: var(--normal-font-size);
}

.additional-info p {
  margin: 10px 0;
}


/* Estilos gerais */.Booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.calendar-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #274C58 !important;
}

.options-list {
  column-count: 2;
  column-gap: 20px;
  padding: 0; /* Remover padding padrão */
  text-align: left; /* Alinhar o texto à esquerda */
}

.courts-list {
margin-bottom: 30px;
}

.options-list li {
  margin-bottom: 10px;
}


.options-list button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff811a;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.courts-list button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 100%; /* Ajuste para ocupar toda a largura disponível */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff811a;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-selection button:not(:last-child) {
  margin-right: 10px; /* Ajuste o valor conforme necessário */
}

.form-selection button:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 100%; /* Ajuste para ocupar toda a largura disponível */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-selection button:last-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 100%; /* Ajuste para ocupar toda a largura disponível */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #15c736;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.options-list button:hover,
.courts-list button:hover,
.navigation-button:hover {
  background-color: #274C58;
}
.options-list button.selected,
.courts-list button.selected {
  background-color: #274C58; /* Cor de fundo para o botão selecionado */
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #274C58 !important;
}

.courts-container {
  margin-top: 20px;
  width: 100%;
}

.courts-list {
  display: flex;
  flex-wrap: wrap; /* Permitir quebra de linha */
  list-style-type: none;
  padding: 0;
}

.courts-list li {
  margin-right: 10px;
  margin-bottom: 10px; /* Espaço adicional entre as linhas */
}

.form-container {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h3 {
  margin-bottom: 20px;
  color: #333;
}

.form-container label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;

  border-radius: 5px;
}

.form-container button {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff811a;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container button:hover {
  background-color: #274C58;
}

.home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.text-section {
  max-width: 40%;
}

.title {
  font-family: var(--second-font);
  color: var(--title-color);
  font-size: var(--biggest-font-size);
  margin-bottom: 1rem;
}

.subtitle {
  font-size: var(--h2-font-size);
  margin-bottom: .5rem;
}

.carousel-section {
  max-width: 40%;
  margin-left: 2rem;
  margin-right: 2rem;
}

.social-icons {
  top: 10rem;
  right: 0;
  display: grid;
  row-gap: 1rem;
}

.home__data{
  text-align: initial;
}

.home__subtitle, .home__title, .home__button{
  font-family: var(--second-font);
  color: var(--title-color);
}

.home__subtitle {
  font-size: var(--h2-font-size);
  margin-bottom: .5rem;
}

.home__title {
  font-size: var(--biggest-font-size);
  margin-bottom: 1rem;
}

.home__description {
  font-size: var(--small-font-size);
  margin-bottom: 2rem;
}

.home__button {
  display: inline-block;
  background-color: var(--white-color);
  box-shadow: 0 8px 32px hsla(0, 0%, 0%, .1);
  padding: 1rem 3rem;
  border-radius: 4rem;
  font-weight: var(--font-bold);
  transition: box-shadow .4s;
}

.home__button:hover {
  box-shadow: 0 8px 32px hsla(0, 0%, 0%, .15);
}

.home__container {
  position: relative;
  display: grid;
  row-gap: 2rem;
  padding-block: 8rem 4rem;
}

.home__social {
  position: absolute;
  top: 10rem;
  right: 0;
  display: grid;
  row-gap: 1rem;
}

.home__social-link{
display: inline-flex;
font-size: 1.25rem;
color: var(--title-color);
transition: transform .4s;
}

.home__social-link:hover {
  transform: translateY(-.25rem);
}

.cancel-button {
  background-color: #e74c3c; /* Vermelho */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #c0392b; /* Vermelho mais escuro */
}

.close-button {
  background-color: #bdc3c7; /* Cinza claro */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #95a5a6; /* Cinza mais escuro */
}

/*File Explorer */


.file-explorer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
}

.header {
  font-size: var(--h2-font-size);
  font-weight: bold;
  margin-bottom: 20px;
  font-family: var(--body-font);
}

.back-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: var(--normal-font-size);
  background-color: #274C58;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #1b6379;
}

.navigation-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  background-color: #ff811a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navigation-button:hover {
  background-color: #274C58;
}

.files-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.thumbnail {
  max-width: 220px;
  max-height: 150px;
  border-radius: 8px;
}

.file-actions {
  margin-top: 10px;
}

.action-button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  background-color: #274C58;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #1b6379;
}

.file-item p {
  margin: 10px 0;
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  text-align: center;
}