.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px; /* Espaçamento entre os itens */
  margin: 20px auto;
  max-width: 1200px;
}

.gallery-item {
  flex: 1 1 calc(33.33% - 10px); /* Define 33.33% do espaço por imagem */
  max-width: calc(33.33% - 10px); /* Garante que o tamanho máximo também seja 33.33% */
  margin-bottom: 10px;
  box-sizing: border-box;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Centraliza a última fileira caso tenha menos de 3 itens */
.gallery-container::after {
  content: '';
  flex: auto;
}

.gallery-header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .gallery-item {
    flex: 1 1 calc(50% - 10px); /* Define 50% do espaço por imagem */
    max-width: calc(50% - 10px); /* Ajusta para telas médias */
  }
}

@media (max-width: 480px) {
  .gallery-item {
    flex: 1 1 100%; /* Define 100% do espaço por imagem para telas pequenas */
    max-width: 100%;
  }
}
