.new-court {
    display: flex;
    justify-content: end;
    margin-bottom : 10px;
}

.input-cpf {
    width: 100%; /* Largura total */
    max-width: 300px; /* Largura máxima */
    padding: 10px; /* Espaçamento interno */
    border: 1px solid #ccc; /* Borda leve */
    border-radius: 5px; /* Cantos arredondados */
    transition: border-color 0.3s, box-shadow 0.3s; /* Transição suave */
  }
  
  .input-cpf:focus {
    border-color: #007bff; /* Cor da borda ao focar */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ao focar */
  }
  
  @media (max-width: 768px) {
    table {
      width: 100%; /* Faz a tabela ocupar 100% do container */
      display: block; /* Exibe a tabela como bloco */
      overflow-x: auto; /* Adiciona rolagem horizontal se necessário */
    }
  
    th, td {
      white-space: nowrap; /* Impede quebra de linha em células */
    }
  }
  